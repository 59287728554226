/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


body{
    background-color: #1D1F33;
}

ion-content{
    --background: transparent;
    font-family: 'Roboto', sans-serif !important;
}

ion-toast {
    text-align: center;
    font-size: 20px
}

ion-title.title-logo img {
    width: 200px;
}

.loading-custom1 .loading-wrapper {
    background: #1d1f33 !important;
    flex-wrap: wrap;
}

.loading-custom1 ion-spinner {
    color: #6FCF97 !important;
    width: 50px;
    height: 50px;
}

.loading-custom1 .loading-wrapper .loading-spinner {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.loading-custom1 .loading-wrapper .loading-content {
    font-size: 20px;
    text-align: center;
    margin: 0 !important;
}

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
